import { Ctrl } from ":mods";
import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { ShowExhibition } from "..";

export function StudentRoutes(props: Ctrl.Routes.model.StudentView) {
  return (
    <>
      <Route path={[ROUTES.student.foundation]} element={<ShowExhibition {...props} />} />
    </>
  );
}
